<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
        <div class="col col-lg-2"></div>
        <div class="col-md-auto mt-20">
          <rotate-square5></rotate-square5>
        </div>
        <div class="col col-lg-2"></div>
      </div>
      <div class="card card-body printableArea" v-else>
        <h3>
          <div class="row">
            <div class="col-md-10 col-12">
              <b>Daftar Surat Masuk Siap Kirim</b>
            </div>
            <div class="col-md-2 col-12" v-if="canSeeAll">
              <select
                class="pull-right form-control"
                v-model="from"
                @change="getFrom"
              >
                <option value="me">Diri Sendiri</option>
                <option value="all">Satu Unit</option>
              </select>
            </div>
          </div>
        </h3>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <Table
              :items="items"
              :filter="filters"
              :onAction="get"
              :onEdit="doEdit"
              :header="headers"
              :onDetail="doDetail"
              :forbidDetail="forbidRead"
              :forbidDelete="true"
              :overideSearch="search"
              @onSearch="onSearch"
            />
          </div>
        </div>
      </div>
    </div>

    <hr />
    <div class="card card-body printableArea">
      <h4>Keterangan</h4>
      <table>
        <tr>
          <td>
            <span class="btn-sm btn-success mr-1"
              ><i class="mdi mdi-magnify"></i
            ></span>
          </td>
          <td style="font-size: 13px">Untuk melihat ada detail surat</td>
        </tr>
        <tr>
          <td colspan="2">&nbsp;</td>
        </tr>
        <tr>
          <td>
            <span class="btn-sm btn-warning mr-1"
              ><i class="mdi mdi-grease-pencil"></i
            ></span>
          </td>
          <td style="font-size: 13px">Untuk mengedit surat masuk</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import Table from "@/components/table/table";
import { RotateSquare5 } from "vue-loading-spinner";

export default {
  components: {
    RotateSquare5,
    Table,
  },
  data() {
    let last = localStorage.getItem("last_request");
    last = last != null ? last.split("?") : ",?,".split("?");
    let params = {};
    if (last[0].includes("document_in")) {
      params = JSON.parse(last[1]);
    }
    if (params.approval == undefined || params.approval != 2) {
      params = {};
    }
    return {
      search: params.search || "",
      from: "all",
      headers: {
        // kecrat: {
        //   label: "Kategori Surat",
        //   sortable: false
        // },
        no_opd: {
          label: "Nomor Kendali",
          sortable: true,
        },
        nomor_surat: {
          label: "Nomor Surat",
          sortable: true,
        },
        asal_surat: {
          label: "Asal Surat",
          sortable: false,
        },
        description: {
          label: "Ringkasan",
          sortable: true,
        },
        sifat: {
          label: "Sifat Surat",
          sortable: true,
        },
        tanggal_diterima: {
          label: "Tanggal Diterima",
          sortable: true,
          type: "date",
        },
        redaksi: {
          label: "Redaksi",
          sortable: false,
        },
        send: {
          label: "Status",
          sortable: false,
        },
      },
    };
  },
  computed: {
    items() {
      let data = [];
      if (this.$store.state.documentInAlreadySend.items !== undefined) {
        if (this.$store.state.documentInAlreadySend.items.items !== undefined) {
          if (
            !Array.isArray(
              this.$store.state.documentInAlreadySend.items.items
            ) &&
            this.$store.state.documentInAlreadySend.items.items !== undefined
          ) {
            data = Object.keys(
              this.$store.state.documentInAlreadySend.items.items
            ).map(
              (i) => this.$store.state.documentInAlreadySend.items.items[i]
            );
          } else {
            data = this.$store.state.documentInAlreadySend.items.items;
          }
        }
      }
      return data;
    },
    filters() {
      let filt = {};
      if (this.$store.state.documentInAlreadySend.items !== undefined) {
        if (
          this.$store.state.documentInAlreadySend.items.filters !== undefined
        ) {
          filt = this.$store.state.documentInAlreadySend.items.filters;
        }
      }
      return filt;
    },
    state() {
      return this.$store.state.documentInAlreadySend;
    },
    forbidUpdate() {
      if (this.$store.state.profile.permissions.document_in) {
        return !this.$store.state.profile.permissions.document_in.update;
      }
      return false;
    },
    canSeeAll() {
      if (this.$store.state.profile.permissions.document_in_all) {
        return this.$store.state.profile.permissions.document_in_all.read;
      }
      return false;
    },
    forbidRead() {
      if (this.$store.state.profile.permissions.document_in) {
        return !this.$store.state.profile.permissions.document_in.read;
      }
      return false;
    },
    forbidDel() {
      return true;
    },
  },
  mounted() {
    const state = {
      loaded: false,
      items: [],
      search: this.search,
      from: this.from,
    };
    this.$store.commit("documentInAlreadySend/STATE", state);
    this.get(state);
  },
  methods: {
    onSearch(val) {
      this.search = val;
    },
    get(val) {
      this.$store.dispatch(
        "documentInAlreadySend/getDocumentInAlreadySend",
        val
      );
    },
    doDetail(val) {
      this.$store.dispatch("documentInAlreadySend/onDetail", val);
    },
    doEdit(val) {
      this.$store.dispatch("documentInAlreadySend/onEdit", val);
    },
    doDelete(val) {
      this.$store.dispatch("documentInAlreadySend/submitDelete", val);
    },
    getFrom() {
      const state = {
        search: this.search,
        from: this.from,
      };
      this.$store.commit("documentInAlreadySend/STATE", state);
      this.get(state);
    },
  },
};
</script>
